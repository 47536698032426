import WidgetsMgr from 'widgets/widgetsMgr';
import HarmonyModal from 'harmony/global/HarmonyModal';
import Modal from 'widgets/global/Modal';
import cancelOrderModal from 'harmony/cancelOrderModal';

WidgetsMgr.addWidgetsList('app_brand_californiabrandyhouse', () => [
    ['modal', Modal],
    ['harmonyModal', HarmonyModal, 'modal'],
    ['cancelOrderModal', cancelOrderModal, 'harmonyModal']
]);
